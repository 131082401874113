import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/23.png";
import ImageCropper from "./image-cropper/ImageCropper";
import mixins from "../../../../app-services/mixins";
import Alertmodal from "../Alertmodal";

const Uploadfive = (props) => {
  const {
    images,
    setImages,
    imagesassets,
    setImagesassets,
    originalImage,
    setOriginalimage,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;

  const [alert, setAlert] = useState({
    image_eight: "Maximum upload size: 5MB",
    image_nine: "Maximum upload size: 5MB",
  });
  const [image1, setImage1] = useState({});
  const [image2, setImage2] = useState({});
  const nameRef = useRef();
  const [newalert, setNewalert] = useState(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!image1?.base64) {
      setAlert({
        ...alert,
        image_eight: "Field is required.",
      });
    } else if (!image2?.base64) {
      setAlert({
        ...alert,
        image_nine: "Field is required.",
      });
    } else {
      setImages({
        ...images,
        image_eight: image1?.file,
        image_nine: image2?.file,
      });
      setImagesassets({
        ...imagesassets,
        image_eight: image1?.base64,
        image_nine: image2?.base64,
      });
      handlePreinfoSave();
    }
  };

  const handleCropCompleteeight = (base64, file) => {
    setImage1({
      base64: base64,
      file: file,
    });
  };
  const handleCropCompletenine = (base64, file) => {
    setImage2({
      base64: base64,
      file: file,
    });
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert({
          image_eight: "Maximum upload size: 5MB",
          image_nine: "Maximum upload size: 5MB",
        });
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (imagesassets?.image_eight) {
      setImage1({
        base64: imagesassets?.image_eight,
        file: images?.image_eight,
      });
    }
    if (imagesassets?.image_nine) {
      setImage2({
        base64: imagesassets?.image_nine,
        file: images?.image_nine,
      });
    }
  }, []);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                {image1?.base64 && (
                  <img src={image1?.base64} className="uploaded_image_eight" />
                )}
                {image2?.base64 && (
                  <img src={image2?.base64} className="uploaded_image_nine" />
                )}
                <img src={titleimg} className="main_image_one" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <div className="mb-2">
                  <h4>PHOTO 8</h4>
                  <p>Upload a group photo.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_eight: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          setImage1({
                            base64: base64String,
                            file: file,
                          });
                        };

                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_eight && (
                    <span
                      style={{
                        color: "red",
                      }}
                      className="mb-2"
                    >
                      {alert?.image_eight}
                    </span>
                  )}
                  {originalImage?.image_eight?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_eight?.base64}
                          onCropComplete={handleCropCompleteeight}
                          ratio={62 / 43}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h4>PHOTO 9 </h4>
                  <p>Upload a photo of a happy moment.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_nine: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          setImage2({
                            base64: base64String,
                            file: file,
                          });
                        };

                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_nine && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.image_nine}
                    </span>
                  )}
                  {originalImage?.image_nine?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_nine?.base64}
                          onCropComplete={handleCropCompletenine}
                          ratio={36 / 26}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alertmodal
        open={newalert}
        handleClose={() => {
          setNewalert(null);
        }}
      />
    </div>
  );
};

export default connect(Uploadfive);

import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/menu-item.jpg";

const Menuitems = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit1, setLimit1] = useState(25);
  const [limit2, setLimit2] = useState(25);
  const [limit3, setLimit3] = useState(25);
  const [limit4, setLimit4] = useState(25);
  const [limit5, setLimit5] = useState(25);

  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!formdata?.menu_one?.trim()) {
      setAlert({
        menu_one: "Field is required.",
      });
    } else if (!formdata?.menu_two?.trim()) {
      setAlert({
        menu_two: "Field is required.",
      });
    } else if (!formdata?.menu_three?.trim()) {
      setAlert({
        menu_three: "Field is required.",
      });
    } else if (!formdata?.menu_four?.trim()) {
      setAlert({
        menu_four: "Field is required.",
      });
    } else if (!formdata?.menu_five?.trim()) {
      setAlert({
        menu_five: "Field is required.",
      });
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    if (formdata?.menu_one?.trim()) {
      setLimit1(25 - formdata?.menu_one?.length || 0);
    } else {
      setLimit1(25);
    }
    if (formdata?.menu_two?.trim()) {
      setLimit2(25 - formdata?.menu_two?.length || 0);
    } else {
      setLimit2(25);
    }
    if (formdata?.menu_three?.trim()) {
      setLimit3(25 - formdata?.menu_three?.length || 0);
    } else {
      setLimit3(25);
    }
    if (formdata?.menu_four?.trim()) {
      setLimit4(25 - formdata?.menu_four?.length || 0);
    } else {
      setLimit4(25);
    }
    if (formdata?.menu_five?.trim()) {
      setLimit5(25 - formdata?.menu_five?.length || 0);
    } else {
      setLimit5(25);
    }
  }, [formdata]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>Create a favourite or dream menu </h4>
                <p>List all the goodies that bring warm memories. </p>
                <div class="form-info mt-3">
                  <p>Favourite food</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite food"
                    className="m-0"
                    maxLength={25}
                    value={formdata?.menu_one || ""}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        menu_one: e.target.value,
                      });
                    }}
                  />
                  <span className="limit">{limit1}</span>
                  {alert?.menu_one && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.menu_one}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite food</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite food"
                    value={formdata?.menu_two || ""}
                    className="m-0"
                    maxLength={25}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        menu_two: e.target.value,
                      });
                    }}
                  />
                  <span className="limit">{limit2}</span>
                  {alert?.menu_two && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.menu_two}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite snack</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite snack"
                    value={formdata?.menu_three || ""}
                    className="m-0"
                    maxLength={25}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        menu_three: e.target.value,
                      });
                    }}
                  />
                  <span className="limit">{limit3}</span>
                  {alert?.menu_three && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.menu_three}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite drink</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite drink"
                    value={formdata?.menu_four || ""}
                    className="m-0"
                    maxLength={25}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        menu_four: e.target.value,
                      });
                    }}
                  />
                  <span className="limit">{limit4}</span>
                  {alert?.menu_four && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.menu_four}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite drink</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite drink"
                    value={formdata?.menu_five || ""}
                    className="m-0"
                    maxLength={25}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        menu_five: e.target.value,
                      });
                    }}
                  />
                  <span className="limit">{limit5}</span>
                  {alert?.menu_five && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.menu_five}
                    </span>
                  )}
                </div>
                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Menuitems);

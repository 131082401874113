import axios from "axios";

export default {
  checkSubscribeUser(pageEditor) {
    let store = pageEditor.props.store;
    if (!store.user?.subscription) {
      if (store.user?.savedBookCount >= 3) {
        return messages.save_book_limitation_warning_unsubscribed_user;
      }
    } else {
      if (store.user?.savedBookCount >= 10) {
        return messages.save_book_limitation_warning_subscribed_user;
      }
    }
    return false;
  },
  // create selfpublish
  createSelfPublish(data) {
    //console.log({ createBookRequeste: data });
    //return new Promise(()=>(''));
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .post(`${base}/api/selfPublishing/create`, data, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((d) => {
            //console.log({ createBookResponce: data });
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Saving the book please wait ..."
    );
  },
  // update selfpublish
  updateSelfPublish(data, id) {
    //console.log({ updateBookRequeste: data });
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .post(`${base}/api/selfPublishing/update/${id}`, data, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((d) => {
            //console.log({ updateBookResponce: d.data });
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Updating the book please wait ..."
    );
  },
  // view selfpublish
  getSelfPublish(id) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .get(`${base}/api/selfPublishing/show/${id}`, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Getting saved book please wait..."
    );
  },
  // delete selfpublish
  deleteSelfPublish(id) {
    return this.handelAlert((res, rej, loadObj) => {
      axios
        .delete(`${base}/api/selfPublishing/delete/${id}`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    }, false);
  },
  // list selfpublish
  listSelfPublish(data) {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .get(`${base}/api/selfPublishing/list?` + Object.QueryString(data), {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      true,
      "Getting the saved page please wait..."
    );
  },

  getUpdatedSelfBook(data, msg = "Getting the saved page please wait...") {
    let defmsg = "Getting the saved page please wait...";
    let msgs = {
      updatemsg: "Updating the book please wait ...",
      createmsg: "Saving the book please wait ...",
    };
    msg = msgs[msg] || defmsg;
    let updateBookList = () =>
      this.handelAlert(
        (res, rej, loadObj) => {
          axios
            .get(
              `${base}/api/selfPublishing/listByUpdate?` +
                Object.QueryString(data),
              {
                headers: {
                  Authorization: `Bearer ${
                    localStorage.getItem("token")
                      ? localStorage.getItem("token")
                      : token
                  }`,
                },
              }
            )
            .then((d) => {
              //console.log({ getUpdatedSelfBook: d.data });
              res(d.data);
            })
            .catch(rej);
        },
        true,
        msg
      );
    if (PageEditor) {
      let { customBookId } = PageEditor.props.match.params;
      customBookId = Number(customBookId);
      if (customBookId) {
        return new Promise((shres, shrej) => {
          this.getSelfPublish(customBookId)
            .then((res) => {
              let book = res.data && Object.keys(res.data).length && res.data;
              shres({ data: book });
            })
            .catch(() => shres({ data: null }));
        });
      } else {
        return new Promise((shres, shrej) => {
          shres({
            data: {
              data: null,
              message: "Creating New Book",
              status: "success",
              statusCode: 200,
            },
          });
        });
      }
    } else {
      return updateBookList();
    }
  },

  getSessionID() {
    return this.handelAlert((res, rej) => {
      axios
        .get(`${base}/api/selfPublishing/getSessionId`, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token")
                ? localStorage.getItem("token")
                : token
            }`,
          },
        })
        .then((d) => {
          res(d.data);
        })
        .catch(rej);
    });
  },

  getSavedBookDetails() {
    return this.handelAlert(
      (res, rej, loadObj) => {
        axios
          .get(`${base}/api/subscription/userSubscriptionDetails`, {
            headers: {
              Authorization: `Bearer ${
                localStorage.getItem("token")
                  ? localStorage.getItem("token")
                  : token
              }`,
            },
          })
          .then((d) => {
            res(d.data);
          })
          .catch(rej);
      },
      false,
      "Getting the saved book details please wait..."
    );
  },
};

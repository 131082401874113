import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/15.png";
import ImageCropper from "./image-cropper/ImageCropper";
import mixins from "../../../../app-services/mixins";
import Alertmodal from "../Alertmodal";

const Uploadone = (props) => {
  const {
    images,
    setImages,
    imagesassets,
    setImagesassets,
    originalImage,
    setOriginalimage,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const ratio = 1 / 1.5;
  const [alert, setAlert] = useState("Maximum upload size: 5MB");
  const nameRef = useRef();
  const [newalert, setNewalert] = useState(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!images?.image_one) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert("Maximum upload size: 5MB");
      }, 3000);
    }
  }, [alert]);
  const handleCropComplete = (base64, file) => {
    setImages({
      ...images,
      image_one: file,
    });
    setImagesassets({
      ...imagesassets,
      image_one: base64,
    });
  };
  // useEffect(() => {
  //   if (originalImage?.image_one?.base64) {
  //     setImages({
  //       ...images,
  //       image_one: originalImage?.image_one?.file,
  //     });
  //     setImagesassets({
  //       ...imagesassets,
  //       image_one: originalImage?.image_one?.base64,
  //     });
  //   }
  // }, [originalImage]);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                {imagesassets?.image_one && (
                  <img
                    src={imagesassets?.image_one}
                    className="uploaded_image_one"
                  />
                )}
                <img src={titleimg} className="main_image_one" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>PHOTO 1</h4>
                <p>Upload a silly photo</p>
                <input
                  ref={nameRef}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  className="m-0"
                  onChange={(e) => {
                    let pass = mixins.filefivemb(e);
                    if (pass?.status) {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String = reader.result;
                        setOriginalimage({
                          ...originalImage,
                          image_one: {
                            base64: base64String,
                            file: file,
                          },
                        });
                        handleCropComplete(base64String, file);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      setNewalert(pass.message);
                    }
                  }}
                />
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}
                {originalImage?.image_one?.base64 && (
                  <div className="row">
                    <div className="col-md-12">
                      <ImageCropper
                        image={originalImage?.image_one?.base64}
                        onCropComplete={handleCropComplete}
                        ratio={1 / 1.5}
                      />
                    </div>
                  </div>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alertmodal
        open={newalert}
        handleClose={() => {
          setNewalert(null);
        }}
      />
    </div>
  );
};

export default connect(Uploadone);
